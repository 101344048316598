import React from "react"
import { Link } from "gatsby"

import "font-awesome/css/font-awesome.min.css"

const footer = props => {
	return (
		<div className="footer-section">
			<div className="footer-menu">
				<div className="container">
					<div className="row">
						<div className="col-md-6 mb-4 mb-md-0">
							<div className="footer-left">
								<h4 className="footer-title">Learn More</h4>
								<p className="section-p text-white">
									Are you looking for something not listed on this page? We
									offer many IT services and solutions for your business. Visit
									our&nbsp;contact page&nbsp;or call us at (303) 408-6028 for
									further assistance.
								</p>
								<p className="footer-address text-white">
									<span style={{ color: "#6fbc1e", fontWeight: 900 }}>
										NeighborhoodIT LLC:&nbsp;
									</span>
									865 Ash St Broomfield, CO 80020
								</p>
								<p className="footer-contact text-green">
									Call Us:&nbsp;
									<a className="text-white" href="tel:3034086028">
										(303) 408-6028
									</a>
								</p>
								<p className="footer-contact text-green">
									Email Us:&nbsp;
									<a
										className="text-white"
										href="mailto:support@neighborhoodit.org"
									>
										support@neighborhoodit.org
									</a>
								</p>
							</div>
						</div>
						<div className="col-md-3 mb-4 mb-md-0">
							<div className="footer-center">
								<h4 className="footer-title">Our Services</h4>
								<ul className="footer-menu ml-0 p-0">
									<li>
										<Link to="/data-protection">Data Protection</Link>
									</li>
									<li>
										<Link to="/hourly-it">Hourly IT Support</Link>
									</li>
									<li>
										<Link to="/managed-services">Manage Services</Link>
									</li>
									<li>
										<Link to="/not-for-profit">
											Not for Profit Home Services
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3 mb-4 mb-md-0">
							<div className="footer-center">
								<h4 className="footer-title">Follow Us</h4>
								<div className="social-icons d-flex align-content-center justify-content-start">
									<a
										className="icon mx-2"
										href="https://twitter.com/ColoradoNIT"
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="fa fa-twitter twitter" />
									</a>
									<a
										className="icon mx-2"
										href="https://www.youtube.com/user/NeighborhoodIT"
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="fa fa-youtube youtube" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-section">
				<div className="container">
					<div className="copyright-content d-md-flex justify-content-between">
						<p className="section-p mb-0 text-black text-center text-md-right">
							©&nbsp;2020 NeighborhoodIT. All rights reserved.
							<br />
						</p>
						<p className="section-p mb-0 text-black text-center text-md-left">
							Designed &amp; developed by&nbsp;
							<a
								href="https://webriq.com/"
								className="text-dark branding"
								target="_blank"
								rel="noopener noreferrer"
							>
								WebriQ
							</a>
							.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default footer
