import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

import LOGO from "../../images/logo.png"

export default ({ toggle }) => (
	<div className="navigation">
		<div className="text-center">
			<Link onClick={toggle} to="/">
				<img className="img-fluid" src={LOGO} alt="Logo" />
			</Link>
		</div>
		<Nav className="flex-column">
			<Nav.Item>
				<Link onClick={toggle} to="/">
					Home
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link onClick={toggle} to="/#services">
					Services
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link onClick={toggle} to="/#about-us">
					About
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link onClick={toggle} to="/#contact-us">
					Consultation
				</Link>
			</Nav.Item>
		</Nav>
	</div>
)
